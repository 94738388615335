<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <suy-table :reload="loadData" v-model:columns="columns">
    <template #search>
      <a-form ref="formQueryRef" layout="inline" :model="searchForm">
        <a-form-item label="">
          <a-input v-model:value="searchForm.name" placeholder="机构名称" />
        </a-form-item>
        <a-form-item>

          <a-divider type="vertical" />
          <a-button type="primary" @click="searchData">
            <template #icon>
              <SearchOutlined />
            </template>查询
          </a-button>
        </a-form-item>
      </a-form>

    </template>
    <template #toolbarRight>
      <a-button type="primary" @click="add">
        <template #icon>
          <PlusCircleOutlined />
        </template>
        新增
      </a-button>
    </template>
    <a-table @expand="onExpand" :columns="[sortColumn, ...columns]" :row-key="record => record.id" :data-source="listData"
      :pagination="pagination" :loading="loading" @change="handleTableChange">
      <!-- <template #sort="{ index }">
        {{ index + 1 }}
      </template> -->
      <template #enable="{ record, index }">
        <!-- <a-switch v-show="record.parentOrgId == 0" v-model:checked="record.isEnable.value" checkedChildren="启用"
          unCheckedChildren="禁用" :checkedValue="1" :disabled="true" :unCheckedValue="0" @click="isEnableChange(record)" /> -->
        <a-popconfirm :title="`你确定${record.isEnable.value === 0 ? '禁用' : '启用'}该机构吗？`" @confirm="adjustment(record, index)">
          <a-switch v-model:checked="record.isEnable.value" checkedChildren="启用" unCheckedChildren="禁用" :checkedValue="1"
            :unCheckedValue="0" @click="isEnableChange(record)" />
        </a-popconfirm>
      </template>
      <template #operation="{ record }">
        <span>
          <a @click="edit(record)">修改</a>
          <a-divider type="vertical" />
          <a-popconfirm v-if="listData.length" title="确定删除?" @confirm="deleteItem(record.id)">
            <a>删除</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a
            @click="leaderShow = true, editData.departmentName = record.name, editData.departmentId = record.id, oldLeader = record.chargeName">修改负责人</a>
        </span>
      </template>
    </a-table>
  </suy-table>
  <a-modal v-model:visible="modalVisible" :title="modalTitle" :maskClosable="false" :confirm-loading="confirmLoading"
    @ok="save">
    <a-form ref="formRef" :model="form" layout="vertical">
      <a-form-item label="机构名称">
        <a-input v-model:value="form.name" />
      </a-form-item>

      <a-form-item label="上级机构">
        <a-select v-model:value="form.parentOrgId" show-search :filter-option="false" :not-found-content="null"
          @select="onSelect" @search="handleSearch">
          <a-select-option v-for="parentOrg in orgList" :key="parentOrg.id">
            {{ parentOrg.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="机构类型">
        <a-select v-model:value="form.orgType.value" show-search :filter-option="false" :not-found-content="null"
          @select="onSelect" @search="handleSearch">
          <a-select-option v-for="orgType in orgTypeList" :key="orgType.value">
            {{ orgType.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="是否独立经营">
        <a-select v-model:value="form.isIndependent.value" show-search :filter-option="false" :not-found-content="null"
          @select="onSelect" @search="handleSearch">
          <a-select-option v-for="isIndependent in isIndependentList" :key="isIndependent.value">
            {{ isIndependent.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="电话">
        <a-input v-model:value="form.tel" />
      </a-form-item>
      <a-form-item label="地址">
        <a-cascader :options="$store.state.app.cityDict" :show-search="{ filter }" :changeOnSelect="true"
          placeholder="请选择地点" style=" width: 100%;" @change="setAddress" />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="leaderShow" title="修改部门负责人" footer="">
    <h3>{{ editData.departmentName }}当前负责人为 <span style="color:#f00">{{ oldLeader }}</span></h3>
    <a-input-search v-model:value="leaderName" placeholder="请输入名称/姓氏" enter-button="查询" @search="searchLeader" />
    <a-list size="small" bordered :loading="searchLoading" :data-source="leaderList">
      <template #renderItem="{ item }">
        <a-list-item>
          <span>{{ item.name }}</span>
          <span>{{ item.no }}</span>
          <a-popconfirm :title="'确认将' + editData.leaderName + '作为 ' + editData.departmentName + '负责人?'" ok-text="是"
            cancel-text="否" @confirm="confirmChoose">
            <a @click="editData.chargeId = item.id, editData.leaderName = item.name">选择此人</a>
          </a-popconfirm>
        </a-list-item>
      </template>
    </a-list>
  </a-modal>
</template>
<script>

// import { page, add as addItem, edit as editItem, enable } from '@/api/system/organzition'
import { list as orgList, page, add as addItem, edit as editItem, enable, editLeader } from '@/api/system/organzition'
import { getOrgList, employeeList } from '@/api/global'
import { toRefs, reactive, onMounted } from 'vue'
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import SuyTable from '@/components/SuyTable'
import { useStore } from 'vuex'
export default {
  setup () {
    const store = useStore()
    const state = reactive({
      loading: false,
      leaderShow: false,
      searchLoading: false,
      leaderName: '',
      leaderList: [],
      oldLeader: '',
      editData: {
        departmentId: '',
        chargeId: '',
        departmentName: '',
        leaderName: ''
      },
      pagination: {
        current: 1,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      searchForm: {},
      listData: [],
      sortColumn: { title: '', slots: { customRender: 'sort' } },
      columns: [
        {
          title: '机构名称',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: '负责人',
          dataIndex: 'chargeName'
        },
        {
          title: '上级机构',
          dataIndex: 'parentOrgName',
          key: 'parentOrgName'
        },
        {
          title: '地址',
          dataIndex: 'address',
          key: 'address'
        },
        {
          title: '性质',
          dataIndex: 'orgType.label',
          key: 'orgType'
        },
        {
          title: '状态',
          dataIndex: 'enable',
          slots: {
            customRender: 'enable'
          }
        },
        {
          title: '操作',
          key: 'operation',
          slots: { customRender: 'operation' }
        }
      ],
      modalVisible: false,
      modalTitle: '',
      confirmLoading: false,
      orgList: [],
      orgTypeList: [{
        label: '办事处',
        value: 1
      }, {
        label: '职能',
        value: 2
      }, {
        label: '车队',
        value: 3
      }, {
        label: '销售',
        value: 4
      }, {
        label: '营运区',
        value: 5
      }],
      isIndependentList: [{
        label: '是',
        value: 1
      }, {
        label: '否',
        value: 0
      }],
      form: {
        orgType: { label: '', value: '' },
        isIndependent: { label: '', value: '' },
        address: {}
      }
    })
    // 同步全局数据
    const everData = () => {
      orgList({ orgType: 1 }).then(res => {
        if (res.code === 10000) {
          store.state.app.orgList = res.data
          // console.log(store.state.app.orgList)
        }
      })
      orgList({}).then(res => {
        if (res.code === 10000) {
          store.state.app.orgTypeList = res.data
          // console.log(store.state.app.orgTypeList)
        }
      })
    }
    const loadData = async () => {
      state.loading = true
      page({
        ...state.searchForm,
        parentOrgId: 0,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records.map(item => {
            return { ...item, children: [] }
          })
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
        }
      }).finally(() => { state.loading = false })

      // console.log(data) // 表格动态数据
    }
    onMounted(loadData)
    const onExpand = (expanded, record) => {
      if (expanded === true) {
        if (record.children.length <= 0) {
          state.loading = true
          page({
            parentOrgId: record.id,
            current: state.pagination.current,
            size: 999
          }).then(res => {
            if (res.code === 10000) {
              res.data.records.forEach(item => {
                item.children = []
              })
              record.children = res.data.records
              state.loading = false
            }
          })
        }
      }
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const searchData = () => {
      state.pagination.current = 1
      loadData()
    }
    const add = () => {
      state.form = {
        orgType: { label: '', value: '' },
        isIndependent: { label: '', value: '' },
        address: {}
      }
      state.modalVisible = true
      state.modalTitle = '新增机构'
    }

    const edit = item => {
      state.form = { ...item }
      state.modalVisible = true
      state.modalTitle = '修改机构'
    }

    const save = () => {
      state.confirmLoading = true
      state.form.address = ''
      if (state.form.id) {
        editItem({ org: { ...state.form } }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.confirmLoading = false
            state.modalVisible = false
            everData()
          }
        }).catch(err => {
          console.log(err)
        })
      } else {
        addItem({ org: { ...state.form } }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.confirmLoading = false
            state.modalVisible = false
            everData()
          }
        }).catch(err => {
          console.log(err)
        })
      }
    }

    const deleteItem = (id) => {

    }
    getOrgList({}).then(res => {
      state.orgList = res.data
      // console.log(res)  // 上级机构 数据
    })
    const isEnableChange = record => {
      if (record.isEnable.value === 0) record.isEnable = { label: '禁用', value: 0 }
      else record.isEnable = { label: '启用', value: 1 }
    }
    const adjustment = (record, index) => {
      const obj = {}
      if (record.parentOrgId === 0) {
        obj.id = state.listData[0].id
        obj.isEnable = state.listData[0].isEnable
      } else {
        obj.id = state.listData[0].children[index].id
        obj.isEnable = state.listData[0].children[index].isEnable
      }
      enable(obj.id, Number(obj.isEnable.value)).then(res => {
        // console.log(res) // 启用/禁用状态设置
        if (res.code === 10000) {
          message.success('设置成功')
          everData()
        } else {
          if (record.parentOrgId === 0) {
            if (state.listData[0].isEnable.value === 0) state.listData[0].isEnable = { label: '启用', value: 1 }
            else state.listData[0].isEnable = { label: '禁用', value: 0 }
          } else {
            if (state.listData[0].children[index].isEnable.value === 0) state.listData[0].children[index].isEnable = { label: '启用', value: 1 }
            else state.listData[0].children[index].isEnable = { label: '禁用', value: 0 }
          }
        }
      })
    }
    const setAddress = (value, selectedOptions) => {
      state.form.address.province = value[0]
      state.form.addresscity = value[1]
      state.form.address.area = value[2]
    }
    const searchLeader = () => {
      state.searchLoading = true
      employeeList({
        current: 1,
        name: state.leaderName,
        size: 10
      }).then(res => {
        if (res.code === 10000) {
          state.leaderList = res.data.records
          state.searchLoading = false
        }
      })
    }
    const confirmChoose = () => {
      editLeader({
        chargeId: state.editData.chargeId,
        id: state.editData.departmentId
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          state.leaderShow = false
          state.leaderList = []
          state.editData = {}
          state.leaderName = ''
          loadData()
        }
      })
    }

    return {
      setAddress,
      ...toRefs(state),
      loadData,
      handleTableChange,
      searchData,
      onExpand,
      add,
      edit,
      save,
      isEnableChange,
      confirmChoose,
      searchLeader,
      deleteItem,
      adjustment
    }
  },
  components: {
    PlusCircleOutlined,
    SearchOutlined,
    SuyTable
  }
}
</script>
